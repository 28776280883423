import { useEffect, useState } from 'react'

import { Button } from '@/libs/common'
import { ModalComponent } from '@/libs/common/modal-component'
import { useAppSelector } from '@/store'

const LOCAL_STORAGE_KEY = 'brave-warning-submitted'

const BraveWarningModal = () => {
  const browserInfo = useAppSelector((state) => state.app.browserInfo)

  const [open, setOpen] = useState(false)

  const handleSubmit = () => {
    setOpen(false)
    localStorage[LOCAL_STORAGE_KEY] = 'true'
  }

  useEffect(() => {
    if (browserInfo.isBrave && localStorage[LOCAL_STORAGE_KEY] !== 'true') {
      setOpen(true)
    }
  }, [browserInfo.isBrave])

  return (
    <ModalComponent
      open={open}
      name="brave-warning"
      title="Brave browser detected"
      message="For the best experience, please disable the ad blocker, as it interferes with the automatic update system. Additionally, if you refresh the website twice quickly, it may get stuck"
    >
      <Button onClick={handleSubmit}>I understand it now</Button>
    </ModalComponent>
  )
}

export { BraveWarningModal }
